<template>
  <div
    v-editable="blok"
    :class="[
      'grid',
      'column-gap',
      'row-gap',
      {
        'grid--full-width': blok.full_width,
        'grid--full-height': blok.full_height,
      },
    ]"
    :style="[gridStyle]"
  >
    <StoryblokComponent
      v-for="childBlok in blok.items"
      :key="childBlok._uid"
      :blok="childBlok"
      :card-padding-data="cardPaddingData"
    />
  </div>
</template>

<script lang="ts" setup>
import type { SbGridStoryblok, SbPluginResponsiveSpacingNumbers } from '@/types'

interface Props {
  blok: SbGridStoryblok
  cardPaddingData?: SbPluginResponsiveSpacingNumbers
}
const props = defineProps<Props>()

const gridStyle = computed(() => {
  return {
    '--column-gap-mobile': `${
      props.blok.column_gap_responsive?.mobile || props.blok.column_gap
    }px`,
    '--column-gap-tablet': `${
      props.blok.column_gap_responsive?.tablet || props.blok.column_gap
    }px`,
    '--column-gap-desktop': `${
      props.blok.column_gap_responsive?.desktop || props.blok.column_gap
    }px`,
    '--row-gap-mobile': `${
      props.blok.row_gap_responsive?.mobile || props.blok.row_gap
    }px`,
    '--row-gap-tablet': `${
      props.blok.row_gap_responsive?.tablet || props.blok.row_gap
    }px`,
    '--row-gap-desktop': `${
      props.blok.row_gap_responsive?.desktop || props.blok.row_gap
    }px`,
  }
})
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  &--full-width {
    width: 100%;
  }
  &--full-height {
    height: 100%;
  }
}

.column-gap {
  --column-gap-mobile: 0;
  --column-gap-tablet: 0;
  --column-gap-desktop: 0;

  column-gap: var(--column-gap-mobile);

  @media (min-width: $breakpoint-md) {
    column-gap: var(--column-gap-tablet);
  }

  @media (min-width: $breakpoint-lg) {
    column-gap: var(--column-gap-desktop);
  }
}

.row-gap {
  --row-gap-mobile: 0;
  --row-gap-tablet: 0;
  --row-gap-desktop: 0;

  row-gap: var(--row-gap-mobile);

  @media (min-width: $breakpoint-md) {
    row-gap: var(--row-gap-tablet);
  }

  @media (min-width: $breakpoint-lg) {
    row-gap: var(--row-gap-desktop);
  }
}
</style>
